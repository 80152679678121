import React, { useState } from 'react'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'

import './menu.scss'

export const Menu = () => {
  const [openMenue, setOpenMenue] = useState(false)
  return (
    <div className='main-menu'>
      <button aria-label='open-site-navigation' className={`main-menu-burger ${openMenue ? 'main-menu-burger--open' : ''}`} onClick={() => setOpenMenue(!openMenue)}>
        <div className='main-menu-burger-line burger-line-1' />
        <div className='main-menu-burger-line burger-line-2' />
        <div className='main-menu-burger-line burger-line-3' />
      </button>
      <div className={`main-menu-container ${openMenue ? 'open' : ''}`}>
        <nav>
          <ul className='menu-list'>
            <li className='menu-list-item'>
              <Link className='menu-list-item-link' to='/' onClick={() => setOpenMenue(false)}>Photography</Link>
            </li>
            <li className='menu-list-item'>
              <Link className='menu-list-item-link' to='/Contact' onClick={() => setOpenMenue(false)}>Contact</Link>
            </li>
            <li className='menu-list-item'>
              <a href='https://timje.se' className='menu-list-item-link' onClick={() => setOpenMenue(false)}>timje.se</a>
            </li>
          </ul>
        </nav>
        {/* <div className='social'>
          <ul className='social-list'>
            <li className='social-item'>max@timje.se</li>
            <li className='social-item'>TheSwedishScout</li>
          </ul>
        </div> */}
      </div>
    </div>
  )
}
