import React from 'react'
import { Menu } from './menu/menu'
import { Link } from 'react-router-dom'

import '../../style/_colors.scss'
import '../../style/_master.scss'
import 'sanitize.css'
import 'sanitize.css/forms.css'
import 'sanitize.css/typography.css'

import './header.scss'

export const Header = () => {
  return (
    <header className='site-header'>
      <Menu />
      <h2 className='page-title'><Link className='page-title__link' to='/'>Max Timje</Link></h2>

    </header>
  )
}
