import React from 'react'
import { Link } from 'react-router-dom'

import './footer.scss'

export const Footer = () => {
  const socalmediaAcounts = [{
    name: 'instagram', link: '#instagram'
  }]
  return (
    // TODO: add logo to middle / lowest box
    <footer className='page-footer'>
      <div className='page-footer__internal-links page-footer__card'>
        <h2 className='page-footer__card__title'>
          Links
        </h2>
        <ul className='page-footer__card__list'>
          <li className='page-footer__card__list__item'>
            <Link className='page-footer__card__list__item__link' to='/'>Home</Link>
          </li>
          <li className='page-footer__card__list__item'>
            <Link className='page-footer__card__list__item__link' to='/Contact'>Contact</Link>
          </li>
        </ul>
      </div>
      <div className='page-footer__social-links page-footer__card'>
        <h2 className='page-footer__card__title'>
          Social media
        </h2>
        <ul className='page-footer__card__list'>
          {socalmediaAcounts.map(socialmediaAcount => (
            <li className='page-footer__card__list__item' key={socialmediaAcount.name}>
              <a className='page-footer__card__list__item__link' href={socialmediaAcount.link}>{socialmediaAcount.name}</a>
            </li>

          ))}
        </ul>
      </div>
      <div className='page-footer__card page-footer__card--logo'>
        <img className="logo" src={`/images/myMark.png`} width="2000px" height="2000px" alt="logo" loading='lazy' />
        <p>Max Timje</p>
      </div>
    </footer>
  )
}
