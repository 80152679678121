import React from 'react'
import { capitalizeFirstLetter } from '../../../util'

import './sectionLink.scss'
/**
 *
 * @param {Array} categorys
 */
export const SectionLink = ({ categorys }) => {
  return (
    <ul className='section-link-list'>
      <li key='blog' className='section-link-list-item'>
        <button className='section-link-list-item-link' onClick={() => document.getElementById('blog').scrollIntoView({behavior:'smooth'})}>
          Upcoming exhibitions
        </button>
      </li>
      {categorys?.map(category => (
        <li key={category} className='section-link-list-item'>
          <button className='section-link-list-item-link' onClick={() => document.getElementById(category).scrollIntoView({behavior:'smooth'})}>
            {capitalizeFirstLetter(category)}
          </button>
        </li>
      ))}
    </ul>
  )
}
