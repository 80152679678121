import React from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom'


import { Header } from './components/header/header'
import { Home } from './components/pages/home/home'
import { Footer } from './components/footer/Footer'
import { Contact } from './components/pages/contact/contact';
import ScrollToTop from './components/scrollToTop';

// const Footer = React.lazy(() => import('./components/footer/Footer'));

export default function BasicExample () {
  return (
    <Router>
      <ScrollToTop />
      <div className='page-container'>
        <Header />

        <Switch>
          <Route exact path='/'>
            <Home />
          </Route>
          <Route path='/contact'>
            <Contact />
          </Route>
        </Switch>
        <Footer />
      </div>
    </Router>
  )
}
