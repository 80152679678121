import React from 'react'
import { JsonLd } from 'react-schemaorg'

export const VisingsokonstRunda2021 = () => {
  return (
    <article className='blog-post'>
      <JsonLd
        item={{
          '@context': 'https://schema.org',
          '@type': 'Event',
          name: 'Max Timje på Visingsö konstrunda',
          location: {
            '@type': 'PostalAddress',
            name: 'Vrixlösa 21',
            streetAddress: 'Vrixlösa 21',
            postalCode: '56034',
            addressLocality: 'Visingsö',
            addressCountry: 'SE'
          },
          startDate: '2021-08-06',
          endDate: '2021-08-08',
          isAccessibleForFree: true,
          organizer: {
            name: 'Visingsö konstrunda',
            url: 'https://www.visingso-konstrunda.se/'
          },
          eventSchedule: {
            '@type': 'Schedule',
            startDate: '2021-08-06',
            endDate: '2021-08-08',
            startTime: '11:00',
            endTime: '17:00',
            repeatFrequency: 'P1D',
            scheduleTimezone: 'Europe/Stockholm'
          },
          description: 'Jag kommer och ställer ut mina bilder. Det finns möjlighet att köpa de bilder jag ställer ut också',
          image: 'https://max.timje.se/images/_MG_9762.jpg',
          eventAttendanceMode: 'https://schema.org/OfflineEventAttendanceMode',
          eventStatus: 'https://schema.org/EventScheduled',
          performer: {
            '@type': 'Person',
            name: 'Max Timje'
          }
        }}
      />
      <header className='blog-post__header'>
        <h3>Visingsö konstrunda 2021</h3>
      </header>
      <picture className='blog-post__feture-image'>
        <img src='/images/konstrunda-logo.png' width='900px' height='900px' alt='logo to visingsö konstrunda' />
      </picture>
      <div className='blog-post__content'>
        <p>
          Nu är det dags igen för Vissingsö konstrunda, jag kommer att hålla till i maskinhallen på Vrixlösa 21 tillsamans med <a href='https://cliffordart.se/'>Teresia Clifford</a>.
        </p>
        <p>
          Vi har öppet mellan 11:00 och 17:00 6 augusti till 8 augusti då är ni varm välkommna att komma förbi.
        </p>
        <p>
          Mer om visingsö konstrunda 2021 kan du hitta på <a href='https://www.visingso-konstrunda.se/'>visingso-konstrunda.se</a>.
        </p>
      </div>
      <footer className='blog-post__footer'>
        Max Timje, 2021-03-31
      </footer>
    </article>
  )
}
