import React from 'react'

import { ImageSection } from '../../imageSection/ImageSection'
import { SectionLink } from '../../header/sectionLinks/sectionLink'
import { VisingsokonstRunda2021 } from './visingsokonstRunda-2021'

import { sets } from './images'

import './Home.scss'
import './blog-post.scss'

export const Home = () => {
  const presentationImage = { src: '/images/_MG_9762.jpg', srcSmall: '/images/thumbnail/_MG_9762.jpg', srcMedium: '/images/_MG_9762.jpg', srcWebp: 'images/webp/_MG_9762.webp', width: 2000, height: 1335 }

  return (
    <div className=''>

      <nav className='picture-nav'>
        <SectionLink categorys={Object.keys(sets)} />
        <picture className='fold-image'>
          <source type='image/webp' srcSet={presentationImage.srcWebp} />
          <source media='(min-height:650px)' srcSet={presentationImage.src} />
          <source media='(min-height:465px)' srcSet={presentationImage.srcMedium} />
          <img src={presentationImage.srcSmall} width='100%' alt='welcome to this max.timje.se' />
        </picture>
        <div className='presentation'>
          <h2>Hi!</h2>
          <p>I'm Max and I am a hobby photographer mostly intrested in nature photography.</p>
          <p>
            I like to play whith light and the suns position to make an intresting images. Often when im out walking, I just stop when I see the image, capturing the moment then and there.
          </p>
          <p>
            My intrest of photography started when i was quite yung and the first camera i remebering using was an old <a href='https://www.olympus-global.com/en/news/2000a/nr000126c960ze.html'>Olympus C 960 zoom</a> and since growing more and more.
          </p>
          <p>Below is some om my favorite photos.</p>
        </div>
      </nav>
      <main>
        <section id='blog' className='blog'>
          <h2>Upcoming exhibitions</h2>
          <VisingsokonstRunda2021 />
        </section>
        {Object.keys(sets).map(set => (<ImageSection key={set} name={set} set={sets[set]} />))}
      </main>
    </div>
  )
}
