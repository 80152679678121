import React from 'react'

import './image.scss'

/**
 *
 * @param {object} image {src: name, width: full image width in px, height: image height in px}
 */
export const Image = ({ image, className = '' }) => {
  return (
    <picture className={`portfolio-picture ${className}`}>
      <source media='' srcSet={`/images/webp/${image.src}.webp`} type='image/webp' />
      <source media='(min-width:465px)' srcSet={`/images/medium/${image.src}.jpg`} />
      <img src={`/images/thumbnail/${image.src}.jpg`} width={image.width} height={image.height} alt={image.alt || image.name} loading='lazy' />
      <figcaption>{image.name}</figcaption>
    </picture>
  )
}
