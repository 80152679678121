import React, { useState } from 'react'
import Lightbox from 'react-image-lightbox'
import { Image } from '../image/image'
import 'react-image-lightbox/style.css'

import './ImageSection.scss'
import { capitalizeFirstLetter } from '../../util'

export const ImageSection = ({ set, name }) => {
  const [lightboxIsOpen, setLightbox] = useState(false)
  const [photoIndex, setPhotoIndex] = useState(1)
  const images = set.map(imagemeta => `/images/${imagemeta.src}`)
  const imagesThumbnail = set.map(imagemeta => `/images/medium/${imagemeta.src}`)
  return (
    <section id={name} className='image-section'>
      <div className='tag'>
        <button className='tag-section-up' aria-label="go to top" onClick={() => document.getElementById('root').scrollIntoView({behavior:'smooth'})}><span className='arrow' /></button>
        <h2 className='tag-section-name'>{capitalizeFirstLetter(name)}</h2>
      </div>
      <div className='image-set'>
        {set.map((image, index) => (<button className='image-set-image-button' key={image.src} onClick={() => { setLightbox(true); setPhotoIndex(index) }}><Image image={image} /></button>))}
      </div>

      {lightboxIsOpen && (
        <Lightbox
          mainSrc={`${images[photoIndex]}.jpg`}
          nextSrc={`${images[(photoIndex + 1) % images.length]}.jpg`}
          prevSrc={`${images[(photoIndex + images.length - 1) % images.length]}.jpg`}
          mainSrcThumbnail={`${imagesThumbnail[photoIndex]}.jpg`}
          nextSrcThumbnail={`${imagesThumbnail[(photoIndex + 1) % imagesThumbnail.length]}.jpg`}
          prevSrcThumbnail={`${imagesThumbnail[(photoIndex + imagesThumbnail.length - 1) % imagesThumbnail.length]}.jpg`}
          // discourageDownloads
          onCloseRequest={() => setLightbox(false)}
          onMovePrevRequest={() => setPhotoIndex((photoIndex + images.length - 1) % images.length)}
          onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % images.length)}
          imageTitle={set[photoIndex].name}
          imageCaption={set[photoIndex]?.description}
        />
      )}

    </section>
  )
}
