// { src: 'file name without extention', name: 'Pathway', description: 'Discription of image', width: 'thumbnail image width', height: 'thumbnail image height' },
export const sets = {
  walks: [
    { src: '_MG_8335', name: 'Pathway', description: 'Captured during a walk on Visingsö', width: '100px', height: '100px' },
    { src: '_MG_8161', name: 'Sun Path' },
    { src: '_MG_5069', name: 'loanly leaf' }
  ],
  nature: [
    { src: 'IMG_4945_01', name: 'The old stump' },
    { src: 'LRM_EXPORT_172088536923835_20190325_115011246', name: 'Pine island' },
    { src: 'IMG_8590', name: 'Jerusalem mill' },
    { src: 'Max-Timje-7', name: 'Loch Eli' }
  ],
  sunlight: [
    { src: '20191226_095629', name: 'Snow top' },
    { src: 'Max-Timje-30', name: 'Tree' },
    { src: 'Max-Timje-19', name: 'Visingsborg' },
    { src: 'IMG_0373_01', name: 'Forest sunset' },
    { src: '_MG_9762', name: 'Bluebary' },
    { src: 'Körsbärsblom', name: 'Cherry blossoms' },
    { src: '_MG_5153', name: 'Damp grass' },
    { src: '_MG_8732', name: 'Friends' }
  ],
  animals: [
    { src: 'Max-Timje-29', name: 'Bees', width: '500px', height: '667px', description: "Bees on my girlfriend's family farm" },
    { src: 'Max-Timje-16', name: 'Squirrel' }
  ]
}
