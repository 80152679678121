import emailjs from 'emailjs-com'
import React, { useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'

import './contact.scss'
import { serviceId, templateId, userId } from './config'

export const Contact = () => {
  const [name, setName] = useState('')
  const [subject, setSubject] = useState('')
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')
  const [notRobot, setNotRobot] = useState(false)
  const [errors, setErrors] = useState([])
  const [sentMail, setSentMail] = useState('')
  const sendEmail = e => {
    e.preventDefault()
    const validation = []
    if (!notRobot) {
      validation.push('captcha is reqired')
    }
    if (validation.length > 0) {
      setErrors(validation)
      return
    }
    const templateParams = {
      reply_to: email, // emailAdress
      message: message, // message text
      from_name: name // name
    }
    console.log('email sent', templateParams)
    emailjs.send(serviceId, templateId, templateParams, userId)
      .then((response) => {
            setSentMail('sent') 
        // TODO: display to user mail is sent
        console.log('SUCCESS!', response.status, response.text)
      }, (err) => {
        console.log('FAILED...', err)
      })
  }
  const onCaptchaChange =  (value) => {
    console.log('Captcha value:', value)
    if (value.length > 0 && value !== 'false') {
      setNotRobot(true)
    }
    console.log({ name, subject, email, message, notRobot })
  }
  return (
    <div className='page'>
      <div className='contact'>
        <h1>Contact</h1>
        <p>Have a question?</p>
        <p>Please do not hesitate to reach out for any reason</p>
        <p>just fill out the form below and hit "Send message".</p>

        <form className={`contact-form ${sentMail}`} onSubmit={sendEmail}>
          <label className='contact-form__name'>
            Name:
            <input required value={name} onChange={(e) =>  setName(e.target.value)} type='text' />
          </label>
          <label className='contact-form__email'>
            E-mail:
            <input required value={email} onChange={(e) => setEmail(e.target.value)} type='email' />
          </label>
          <label className='contact-form__subject'>
            Subject:
            <input required value={subject} onChange={(e) => setSubject(e.target.value)} type='text' />
          </label>
          <label className='contact-form__message'>
            Message:
            <textarea required value={message} onChange={(e) => setMessage(e.target.value)} />
          </label>
          <div
            className='contact-form__captcha'
          >
            <ReCAPTCHA
              sitekey='6LeXVaQaAAAAAEYRfh3FwtL-tASmN_yz24XuRtrQ'
              onChange={onCaptchaChange}
            />
          </div>
          <span>{errors.join(', ')}</span>
          <input className='contact-form__submit contact-form__submit--primary' value='Send message' type='submit' />
        </form>
        <div className={`email-sent ${sentMail}`}>
          <h2>Thank you for your email</h2>
        </div>
      </div>
    </div>
  )
}
